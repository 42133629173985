/* eslint-disable prefer-template */

export default function (appConfig, browserId) {
	this.customer = appConfig.customer;

	this.loginUrl = appConfig.authServer_loginUrl;
	this.logoutUrl = appConfig.authServer_logoutUrl;
	this.issuers = prepareIssuers(appConfig.authServer_issuers);

	this.loggedOutUrl = replaceCustomer(appConfig.loggedOutUrl, this.customer);

	this.clientId = appConfig.clientId;

	this.browserId = browserId.get();

	this.publicKey = appConfig.authServer_publicKey;
	this.secondaryPublicKeys = appConfig.authServer_secondaryPublicKeys;

	this.sessionTimeout = appConfig.session_timeout;
	this.warningTimeout = appConfig.warning_timeout;

	this.refreshTokenSecBeforeExpiration = appConfig.refreshToken_secBeforeExpiration;
	this.refreshTokenTimeout = appConfig.refreshToken_timeout;
	this.reloginSecBeforeExpiration = appConfig.relogin_secBeforeExpiration;

	this.logoutUrlWithReturn = (returnUrl, isFirstEntrance, reason, idToken) => (logoutUrlWithReturn(this.logoutUrl, returnUrl, this.loggedOutUrl, isFirstEntrance, reason, this.clientId, idToken));

	this.loggedOutPageUrlWithReturn = returnUrl => (
		loggedOutPageUrlWithReturn(this.loggedOutUrl, returnUrl)
	);

	this.loginUrlFor = (loginState, returnUrl) => (
		appendQueryMark(this.loginUrl) +
			"&response_type=id_token%20token" +
			"&scope=openid" +
			"&client_id=" + this.clientId +
			"&redirect_uri=" + encodeURIComponent(returnUrl) +
			"&state=" + loginState.state +
			"&nonce=" + loginState.nonce +
			"&browser_id=" + this.browserId
	);

	function prepareIssuers(issuers) {
		return issuers.split(",");
	}
	function replaceCustomer(input, customer) {
		if (input) {
			return input.replace(/#customerName#/g, customer);
		}
		return input;
	}

	function appendQueryMark(url) {
		return url.indexOf("?") !== -1 ? url : url + "?";
	}

	function logoutUrlWithReturn(logoutUrl, returnUrl, loggedOutUrl, isFirstEntrance, reason, clientId, idToken) {
		const redirectUri = isFirstEntrance ? returnUrl : loggedOutPageUrlWithReturn(loggedOutUrl, returnUrl);
		const reasonParam = reason && typeof reason === "string" ? "&reason=" + reason : "";

		return appendQueryMark(logoutUrl)
			+ "&redirect_uri=" + encodeURIComponent(redirectUri + reasonParam)
			+ "&post_logout_redirect_uri=" + encodeURIComponent(redirectUri + reasonParam)
			+ "&client_id=" + clientId
			+ `${idToken ? "&id_token_hint=" + idToken : ""}`;
	}

	function loggedOutPageUrlWithReturn(loggedOutUrl, returnUrl) {
		return loggedOutUrl ? appendQueryMark(loggedOutUrl) + "&return_uri=" + encodeURIComponent(returnUrl) : returnUrl;
	}
}
