export default function (authContext, authActions, interval, loginState, log) {
	this.track = () => {
		let logoutExpiredSessionsPromise;
		function logoutExpiredSessions() {
			if (authContext.isSessionExpired()) {
				log.info("Cleanup of expired tokens.");
				interval.cancel(logoutExpiredSessionsPromise);
				if (!authContext.isLogoutInProgress()) {
					if (authContext.getIdToken()) {
						authActions.redirectToLogoutPageAndReturnToCurrentPage(false);
					} else {
						authActions.redirectToLoginPageReuseCurrentLogin();
					}
				}
			}
		}

		interval(logoutExpiredSessions, 3 * 1000);
	};
}
