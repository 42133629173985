import utcTime from "support/utcTime";

export default function (activityTracker, tokens, storage, previousUserStorage) {
	const accessTokenStorage = storage.crossTab("NexusAccess");
	const idTokenStorage = storage.crossTab("NexusIdToken");
	const expStorage = storage.crossTab("NexusExpiration");
	const usernameStorage = storage.crossTab("NexusUsername");
	const sessionSwitchStorage = storage.crossTab("NexusSessionSwitch");
	let logoutInProgress = false;

	function isTokenNotExpired() {
		const expiration = expStorage.getInt();
		return !!expiration && expiration > utcTime.now();
	}

	function hasExpiredToken() {
		const accessToken = getAccessToken();
		const expiration = expStorage.getInt();
		return accessToken && expiration && expiration <= utcTime.now();
	}

	function isSessionExpired() {
		return !isSessionOn() || activityTracker.isSessionExpired();
	}

	function isSessionNotExpired() {
		return !isSessionExpired();
	}

	function getAccessToken() {
		return accessTokenStorage.get();
	}

	function getIdToken() {
		return idTokenStorage.get();
	}

	function isSessionOn() {
		return !!sessionSwitchStorage.getInt();
	}

	function sessionOn() {
		sessionSwitchStorage.set(1);
	}

	function getExpValue() {
		return expStorage.getInt();
	}

	this.login = (parsedAccessToken, idToken) => {
		accessTokenStorage.set(parsedAccessToken.raw);
		idTokenStorage.set(idToken);
		expStorage.set(parsedAccessToken.payload.exp);
		usernameStorage.set(parsedAccessToken.payload.sub);
		sessionOn();
	};

	this.logout = () => {
		logoutInProgress = true;
		previousUserStorage.store(usernameStorage.get());
		storage.clear();
	};

	this.isLogoutInProgress = () => (
		logoutInProgress
	);

	this.isLogged = () => (
		isTokenNotExpired() && isSessionNotExpired()
	);

	this.isNotLogged = () => (
		!this.isLogged()
	);

	this.username = () => (
		usernameStorage.get()
	);

	this.usernameIsChangedAfterLogin = () => (
		previousUserStorage.hasDifferentUser(usernameStorage.get())
	);

	this.debugInfo = () => (
		`isLogged: ${this.isLogged()}, isTokenNotExpired: ${!hasExpiredToken()},
		isSessionNotExpired: ${isSessionNotExpired()},
		expValue: ${getExpValue()}, username: ${this.username()}, sessionSwitch: ${isSessionOn()}`
	);

	this.tokenWillExpireIn = (secs) => {
		const accessToken = getAccessToken();
		return tokens.willExpireIn(accessToken, secs);
	};
	this.hasExpiredToken = hasExpiredToken;
	this.isSessionExpired = isSessionExpired;
	this.isSessionNotExpired = isSessionNotExpired;
	this.getAccessToken = getAccessToken;
	this.getIdToken = getIdToken;
	this.getExpValue = getExpValue;
}
